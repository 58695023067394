import VanillaModal from 'modal-vanilla'

const Modal = () => {
    let handler = (e, trigger) => {
        if (e.defaultPrevented) {
            return
        }

        let modalTarget = trigger.getAttribute('data-target');
        let el = document.querySelector(modalTarget);

        if (el) {
            new VanillaModal({el: el}).on('show', () => window.dispatchEvent(new Event('resize'))).show();
        }
    }

    document.addEventListener('click', function(e) {
        // loop parent nodes from the target to the delegation node
        for (var target = e.target; target && target != this; target = target.parentNode) {
            if (target.matches('[data-toggle="modal"]')) {
                handler.call(target, e, target);
                break;
            }
        }
    }, false);
}

export default Modal
